import { cn } from '@trustblock/helpers/classname';
import type React from 'react';
import { ICONS, getIconComponent } from './icons/index';
export type IconName = keyof typeof ICONS;
export const iconOptions = Object.keys(ICONS) as (keyof typeof ICONS)[];

interface IconProps {
  name: IconName;
  className?: string;
  style?: React.CSSProperties;
}

function Icon({ name, className = '', ...props }: IconProps) {
  const IconComponent = getIconComponent(name);
  const baseClassName = `icon-${name.toLowerCase()}`;

  return (
    <IconComponent
      data-testid={`icon-${name.toLowerCase()}`}
      className={cn('w-5 stroke-[1.5px]', baseClassName, className)}
      {...props}
    />
  );
}

export default Icon;
