import { cn } from '@trustblock/helpers/classname';
import React from 'react';
import Spinner from './Oval';

interface LoadingProps {
  mode?: 'button';
  color?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const sizeClassname = {
  small: 'size-4',
  medium: 'size-6',
  large: 'size-10',
  xl: 'size-16'
};

const modeClassname = {
  button: 'color-inherit'
};

const colorClassname = {
  light: 'stroke-current',
  dark: 'stroke-black50'
};

function Loading({ mode, color = 'dark', size = 'large', className }: LoadingProps) {
  return (
    <Spinner className={cn(mode ? modeClassname[mode] : '', colorClassname[color], sizeClassname[size], className)} />
  );
}

export default Loading;
